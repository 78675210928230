<template>
  <div>
    <helloWorld />
    <div class="content-1020">
      <div class="top-info">
        <div class="works-pic">
          <img :src="collectInfo.pic" alt="">
        </div>
        <div class="work-info">
          <div style="display:flex;align-items:center;justify-content:space-between">
            <p class="work-title">{{collectInfo.colName}}</p>
            <div class="end-time" v-if="collectInfo.saleMode!=0">起售日期：{{collectInfo.saleAt}}</div>
          </div>
          <div class="contentWrap">
            <!-- <img :src="collectInfo.avatar" alt="" style="border-radius:50%"> -->
            <a class="from-name" @click="toUser">创作者：{{creator.nickname}}</a>
            <img @click="getLike" v-if="collectInfo.isLike == true" src="@/assets/likes.svg" alt="" style="width:18px;margin-left:18px;margin-right:2px">
            <img @click="getLike" v-if="collectInfo.isLike == false" src="@/assets/xihuan.svg" alt="" style="width:18px;margin-left:18px;margin-right:2px">
            <a class="operation-num">{{collectInfo.likes}}</a>
            <img class="operation" src="@/assets/views.png" alt="">
            <a class="operation-num">{{collectInfo.views}}</a>
            <i class="el-icon-star-on" v-if="collectInfo.isCollect == false" @click="getCollect" style="margin-left:20px;color:#999;font-size:18px"></i>
            <a class="operation-num" v-if="collectInfo.isCollect == false" @click="getCollect">收藏</a>
            <i class="el-icon-star-on" v-if="collectInfo.isCollect == true" @click="getCollect" style="margin-left:20px;color:#E6A23C;font-size:18px"></i>
            <a class="operation-num" v-if="collectInfo.isCollect == true" @click="getCollect">已收藏</a>
          </div>
          <div class="price-info">
            <p class="id">作品ID： {{collectInfo.colCode}}</p>
            <div class="price-box">
              <p v-if="collectInfo.saleMode != 0">单价：<span>￥{{collectInfo.price}}</span></p>
              <p>剩余数量：<span style="font-size:14px;color:#666">{{collectInfo.remainNum}}份</span></p>
              <div class="btn" v-if="pc">
                <div class="bid" @click="send" v-if="collectInfo.saleMode == 0 && userInfo.id == collectInfo.createdBy">上架</div>
                <el-button class="buy-btn" :disabled="(sale==false || collectInfo.remainNum==0)" type="primary" style="border-radius:20px;padding:10px 30px;margin-left:20px" v-if="collectInfo.saleMode != 0 && userInfo.id != collectInfo.createdBy" @click="toBuy">购买</el-button>
                <!-- <div class="buy" style="margin-left:20px" v-if="collectInfo.saleMode != 0 && userInfo.id != collectInfo.createdBy" @click="toBuy">购买</div> -->
                <!-- <div class="bid" @click="bidding">竞价</div> -->
              </div>
            </div>
          </div>
          <div class="contentWrap4">
            <div class="makeMsgTitle">
              <div>
                区块链信息
              </div>
            </div>
            <div class="makeMsgContent">
              <div class="betweenWrap">
                <div class="makeKey">合约地址</div>
                <div class="makeValueBlue"> 28bbab9c*******efbc49f5 </div>
              </div>
              <!-- <div class="betweenWrap">
                <div class="makeKey">链 ID</div>
                <div class="makeValue"> 34 </div>
              </div>
              <div class="betweenWrap">
                <div class="makeKey">所在链</div>
                <div class="makeValue"> 长安链 </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="goodsDetailWrap">
        <div class="makeMsgTitle">
          <div>作品介绍</div>
        </div>
        <div class="content-desc">
          {{collectInfo.content}}
        </div>
      </div>
      <div class="goodsDetailWrap phone-hide">
        <div class="makeMsgTitle">
          <div>交易记录</div>
        </div>
        <div class="recordDataWrap">
        <div class="listTitle">
          <div style="width:16.6%">事件</div>
          <div style="width:16.6%">单价</div>
          <div style="width:16.6%">交易金额</div>
          <div style="width:16.6%">从</div>
          <div style="width:16.6%">至</div>
          <div style="width:16.6%">交易日期</div>
        </div>
        <div class="listContents">
          <div class="listContent" v-for="(item, index) in transactionList" :key="index+'j'">
            <div class="contentWrapj" style="width:16.6%">
              <div class="noMargin">交易</div>
            </div>
            <div class="contentWrapj" style="width:16.6%">
              <div class="noMargin">{{item.unitPrice}}</div>
            </div>
            <div class="contentWrapj" style="width:16.6%">
              <div class="noMargin">{{item.amount}}</div>
            </div>
            <div class="contentWrapj" style="width:16.6%">
              <div @click="toHome" class="noMargin" style="display:flex;align-items:center;justify-content:center;cursor:pointer">
                <img style="width:20px;border-radius:50%;margin-right:4px" :src="item.saleImg" alt="">
                {{item.saleName}}</div>
            </div>
            <div class="contentWrapj" style="width:16.6%;text-align:center">
              <img style="width:20px;border-radius:50%;margin-right:4px" :src="item.buyerImg" alt="">{{item.buyerName}}
            </div>
            <div class="contentWrapj" style="width:16.6%">
              <div class="noMargin">{{item.created}}</div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div class="goodsDetailWrap phone-hide">
        <div class="makeMsgTitle">
          <div>其它收藏品</div>
        </div>
        <div class="works">
          <div class="works-item" v-for="(item, ind) in collectList" :key="ind" v-show="ind<4" @click="toDetail(item)">
            <div class="img-box">
              <img class="collection-picture" :src="env + item.pic" style="height:200px">
            </div>
            <div style="padding:0 14px">
              <p class="works-name">{{item.colName}}</p>
              <p class="creator" style="margin-bottom:6px">{{item.creator.nickname}}</p>
              <div class="price">
                <p>当前价格：<span>￥{{item.price}}</span></p>
                <!-- <p>成交价格：<span>￥{{item.floorPrice}}</span></p> -->
              </div>
              <div class="likenum">
                <img src="@/assets/xihuan.svg" alt="">
                <a>{{item.likes}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
    :close-on-press-escape="false"
      :close-on-click-modal="false"
      title="数字藏品上架"
      :visible.sync="dialogVisible"
      width="320px"
      :before-close="handleClose">
      <div class="publish-dialog">
        <div>
          <el-radio v-model="ruleForm.saleMode" label="1" @change="choose">上架数字藏品</el-radio>
          <!-- <el-radio v-model="ruleForm.saleMode" label="2" @change="choose">竞拍数字藏品</el-radio> -->
        </div>
        <div v-if="tanNum=='1'" style="margin-top:20px;padding-left:20px">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
            <el-form-item label="出售价格" prop="Price">
              <el-input v-model="ruleForm.Price" size="small" style="width:220px"></el-input>
            </el-form-item>
            <el-form-item label="起售时间" prop="SaleAt">
              <el-date-picker
                 size="small"
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model="ruleForm.SaleAt"
                type="datetime"
                placeholder="选择日期时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item style="text-align:center">
              <el-button type="primary" @click="submitForm('ruleForm')" size="small">确定</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div v-if="tanNum=='2'" style="margin-top:20px;padding-left:20px">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
            <el-form-item label="起售时间" prop="SaleAt">
              <el-date-picker
                 size="small"
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model="ruleForm.SaleAt"
                type="datetime"
                placeholder="选择日期时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="起拍价：" prop="BidInit">
              <el-input v-model="ruleForm.BidInit" size="small" style="width:220px"></el-input>
            </el-form-item>
            <el-form-item label="加价幅度：" prop="BidStep">
              <el-input v-model="ruleForm.BidStep" size="small" style="width:220px"></el-input>
            </el-form-item>
            <el-form-item label="竞拍结束时间：" prop="BidEndAt">
              <el-date-picker
                size="small"
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model="ruleForm.BidEndAt"
                type="datetime"
                placeholder="选择日期时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item style="text-align:center">
              <el-button type="primary" @click="submitForm('ruleForm')" style="margin:auto" size="small">确定</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span> -->
    </el-dialog>
    <el-dialog
    :close-on-press-escape="false"
      :close-on-click-modal="false"
      title="缴纳保证金"
      :visible.sync="bondDialog"
      width="480px"
      :before-close="handleClose">
      <div>
        <div style="text-align:center;margin-bottom:10px">
          <i class="el-icon-warning-outline" style="font-size:24px;color:rgb(236, 158, 61)"></i>
        </div>
        <div>
          <p style="margin:0px 0 10px 0;font-size:12px;letter-spacing:1px;line-height:18px">参与竞价需缴纳保证 <span style="color:red">¥ 100</span> ，保证金不计入总价，竞拍未成功或成功购买该商品后，平台将退还至您的账户；若竞拍成功后未及时付款，平台将扣除您的保证金；</p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click="agree">同意</el-button>
      </span>
    </el-dialog>
    <el-dialog
    :close-on-press-escape="false"
      :close-on-click-modal="false"
      title="竞价"
      :visible.sync="bidDialog"
      width="480px"
      :before-close="handleClose">
      <div>
        <div>
          <p style="margin:0px 0 10px 0">价格：</p>
          <el-input size="samll" placeholder="请输入价格" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="bidDialog = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import helloWorld from '@/components/tabBar'
import { publish, getCollectDetail, getCodeBase, toCollect, toLike, downImg, getCollectList } from '../../api/index'
import { mapGetters } from 'vuex'
export default {
  name: 'Detail',
  components: {
    helloWorld
  },
  mounted() {
    this.ruleForm.colCode = this.$route.query.colCode
    if (!this.accessToken || this.accessToken == '') {
      this.$router.push('./')
      return
    }
    if (this._isMobile()) {
      this.pc = false
    } else {
      this.pc = true
    }
    this.getDetail()
    this.getUser()
    this.getCollectList()
    this.obj = this.$route.query
  },
  data() {
    return {
      pc: true,
      creator: {},
      env: process.env.VUE_APP_BASE_API,
      otherList: [
        {
          title: 'Chunky Superman',
          img: require('@/assets/o1.png'),
          author: '亚联融汇',
          price: '12.00',
          floorPrice: '15.02',
          like: 21
        },
        {
          title: 'Ninja Swag',
          img: require('@/assets/o2.jpg'),
          author: '不美不媚',
          price: '28.10',
          floorPrice: '15.02',
          like: 18
        },
        {
          title: 'FRANKLIN #1726',
          img: require('@/assets/o3.jpg'),
          author: '亚联融汇',
          price: '16.32',
          floorPrice: '15.02',
          like: 14
        },
        {
          title: 'Glass Apes',
          img: require('@/assets/o5.jpg'),
          author: 'Summer',
          price: '8.36',
          floorPrice: '15.02',
          like: 3
        },
      ],//其它收藏品
      transactionList: [
        {
          unitPrice: '9.25',
          amount: '9.00',
          saleImg: require(`@/assets/saleImg1.jpg`),
          saleName: '浅水鱼',
          buyerImg: require(`@/assets/saleImg2.jpg`),
          buyerName: '戴小戴',
          created: '2022-02-18 12:28:13'
        },
        {
          unitPrice: '35.00',
          amount: '42.73',
          saleImg: require(`@/assets/saleImg2.jpg`),
          saleName: '戴小戴',
          buyerImg: require(`@/assets/saleImg4.jpg`),
          buyerName: '不美不媚',
          created: '2022-08-26 22:41:05'
        }
      ], //交易记录
      dialogVisible: false,
      radio: '1',
      tanNum: '1',
      bidDialog: false,
      bondDialog: false,
      ruleForm: {
        saleMode: '1',
        Price: '', //价格
        SaleAt: '', //起售时间
        BidInit: '', //起拍价
        BidStep: '', //加价幅度
        BidEndAt: '', //竞拍结束时间
        colCode: ''
      },
      sale: false,
      rules: {
        Price: [
          { required: true, message: '请输入出售价格', trigger: 'blur' }
        ],
        SaleAt: [
          { required: true, message: '请选择起售时间', trigger: 'blur' }
        ]
      },
      obj: {},
      collectInfo: {},
      userInfo: {},
      collectList: []
    }
  },
  computed: {
    ...mapGetters(['accessToken', 'userCode'])
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    toUser() {
      this.$router.push('../user?userCode=' + this.collectInfo.creator.userCode + '&&id='+this.collectInfo.creator.id)
    },
    toDetail(item) {
      this.$router.push('./detail?colCode=' + item.colCode)
      this.ruleForm.colCode = item.colCode
      this.getDetail()
    },
    getCollectList() {
      getCollectList().then(res => {
        if (res.code == 0) {
          this.collectList = res.data.list
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 点赞
    getLike() {
      let obj = {
        colCode: this.collectInfo.colCode
      }
      toLike(obj).then(res => {
        if (res.code == 0) {
          this.$message.success('操作成功')
          this.getDetail()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 收藏
    getCollect() {
      let obj = {
        colCode: this.collectInfo.colCode
      }
      toCollect(obj).then(res => {
        if (res.code == 0 ){
          this.$message.success('操作成功')
          this.getDetail()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    toBuy() {
      this.$router.push('./buy?colCode=' + this.collectInfo.colCode)
    },
    getUser() {
      let data={
        userCode: this.userCode
      }
      getCodeBase(data).then(res => {
        if (res.code == 0) {
          this.userInfo = res.data
        }else {
          this.$message.error(res.message)
        }
      })
    },
    judgeTime (time)  {
      var strtime = time.replace("/-/g", "/");//时间转换
      //时间
      var date1 = new Date(strtime);
      //现在时间
      var date2 = new Date();
      //判断时间是否过期
      return date2 >= date1 ? true : false;
    },
    getDetail() {
      let a = {
        colCode: this.ruleForm.colCode
      }
      getCollectDetail(a).then(res => {
        if(res.code == 0) {
          this.collectInfo = res.data
          this.sale = this.judgeTime(this.collectInfo.saleAt)
          this.creator = res.data.creator
          if (this.collectInfo.saleMode == 0) {
            let a = {
              path: this.collectInfo.pic
            }
            downImg(a).then(res => {
              let blob = new Blob([res]);   // 返回的文件流数据
              let url = window.URL.createObjectURL(blob);  // 将他转化为路径
              this.collectInfo.pic = url;
            })
          } else {
            this.collectInfo.pic = this.env + this.collectInfo.pic
          }
        } else {
          this.$message.error(res.message)
          this.$router.push('./')
        }
        
      })
    },
    submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$set(this.ruleForm, 'colCode', this.$route.query.colCode)
            publish(this.ruleForm).then(res => {
              if (res.code === 0) {
                this.$message.success('上架成功!');
                this.dialogVisible = false
                this.getDetail()
              } else {
                this.$message.error(res.message);
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
    send() {
      this.dialogVisible = true
      this.radio = '1'
      this.tanNum = '1'
      this.ruleForm = {
        saleMode: '1',
        Price: '', //价格
        SaleAt: '', //起售时间
        BidInit: '', //起拍价
        BidStep: '', //加价幅度
        BidEndAt: '', //竞拍结束时间
      };
      this.resetForm("ruleForm");
    },
    resetForm(refName) {
      if (this.$refs[refName]) {
        this.$refs[refName].resetFields();
      }
    },
    toHome() {
      this.$router.push('./radicalDreamer')
    },
    handleClose(done) {
      done()
    },
    choose(val) {
      this.tanNum = val
    },
    bidding() {
      this.bondDialog = true
    },
    agree() {
      this.bondDialog = false
      this.bidDialog = true
    }
  }
}
</script>
<style scoped>
.content-1020 {
  max-width: 1020px;
  margin: auto;
  padding: 50px 0;
}
.top-info {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.works-pic img {
  max-width: 350px;
  height: 350px;
  border-radius: 20px;
  object-fit: cover;
}
.work-title {
  font-size: 22px;
    color: #333;
    font-family: PingFangSC,PingFangSC-Semibold;
    font-weight: 600;
}
.contentWrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 4px;
}
.contentWrap img {
  width: 30px;
}
.from-name {
  font-size: 12px;
    color: #333;
    margin-left: 6px;
    cursor: pointer;
}
.operation {
  width: 40px !important;
  margin-left: 16px;
  cursor: pointer;
}
.img-box {
  width: 100%;
  max-height: 250px;
  text-align: center;
}
.collection-picture {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
  height: 230px;
}
.likenum img{
  width: 18px !important;
  margin-right: 4px;
}
.operation-num {
    font-size: 12px;
    color: #666;
    margin-left: 2px;
}
.work-info {
  /* max-width: 700px; */
  width: 58%;
}
.end-time {
  font-weight: 400;
    font-size: 12px;
    color: rgb(112, 122, 131);
}
.price-info{
  border: 1px solid rgb(229, 232, 235);
  border-radius: 10px;
  margin-top: 10px;
  /* padding: 10px; */
}
.id {
  font-size: 12px;
    color: #666;
    padding: 10px;
    background: #f8f8f8;
}
.price-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.price-box p {
  line-height: 44px;
    font-size: 14px;
    color: #666;
}
.price-box p span {
  font-size: 24px;
  color: #df2723;
}
.buy {
  background: rgb(32, 129, 226);
  color: #fff;
  padding: 8px 40px;
  border-radius: 20px;
  margin-left: 14px;
  cursor: pointer;
}
.bid {
  background: #fff;
  color: rgb(32, 129, 226);
  padding: 8px 40px;
  border-radius: 20px;
  margin-left: 14px;
  border: 1px solid rgb(32, 129, 226);
  cursor: pointer;
}
.btn {
  display: flex;
}
.contentWrap4 {
    width: 100%;
    float: left;
    border: 1px solid #e8e8e8;
    border-radius: 10px;
    margin-top: 20px;
}
.makeMsgTitle {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    background: #f8f8f8;
    padding: 11px 0 11px 15px;
    border-radius: 10px 10px 0 0;
}
.makeMsgTitle div {
    /* margin-left: 11px; */
    color: #333;
    font-size: 14px;
    /* font-weight: 600; */
}
.makeMsgContent {
    padding-top: 13px;
}
.makeMsgContent .betweenWrap {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
    padding: 0 15px 17px 15px;
}
.makeMsgContent .betweenWrap .makeKey {
    font-size: 14px;
    color: #333;
}
.makeMsgContent .betweenWrap .makeValueBlue {
    font-size: 14px;
    color: #3389e4;
}
.makeValue {
    font-size: 14px;
    color: #666;
}
.goodsDetailWrap {
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  margin-top: 30px;
}
.content-desc {
  padding: 20px 20px;
  font-size: 14px;
  color: #666;
  line-height: 22px;
  letter-spacing: 1px;
  height: 200px;
  overflow-y: scroll;
}
.recordDataWrap {
    color: #333;
    border: 1px solid #e8e8e8;
}
.recordDataWrap .listTitle {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    font-size: 14px;
    background: #f8f8f8;
    justify-content: space-between;
    padding: 10px 0;
}
.recordDataWrap .listContents {
    width: 100%;
    max-height: 230px;
    overflow: auto;
    overflow-x: hidden;
}
.recordDataWrap .listContents .listContent:last-child {
    border-bottom: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.recordDataWrap .listContents .listContent {
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #e8e8e8;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    font-size: 14px;
}
.listTitle div {
  text-align: center;
}
.noMargin {
  text-align: center;
}
.transaction {
  position: relative;
}
.works {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-width: 1180px;
  margin: auto;
  margin-top: 20px;
}
.works-item {
  margin: 10px 20px;
  border: 1px solid #eee;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}
.works-item img {
  width: 100%;
}
.works-item:hover {
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
}
.works-name {
  font-size: 18px;
  margin-top:4px;
  display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 1; /*超出几行后省略号*/
overflow: hidden;
}
.creator {
  font-size: 12px;
  color: #666;
  margin-top:6px;
  margin-bottom: 12px;
}
.price {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 4px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}
.price p {
  color: #999;
}
.price p span {
  color: #333;
}
.likenum {
  padding: 8px 0;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.likenum a {
  font-size: 14px;
  color: #999;
  /* margin-left: 4px; */
}
@media screen and (max-width: 950px){
  .top-info{
    display: flex;
    flex-direction: column;
  }
  .work-info {
    width: 90%;
    margin: auto;
  }
  .works-pic {
    margin: auto;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 720px){
  .works{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (min-width: 720px) and (max-width: 900px){
  .works{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
/* @media only screen and (min-width: 720px) and (max-width: 900px){
  .works{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
} */
</style>
<style>
.publish-dialog .el-form-item__label {
  line-height: 23px;
}
.publish-dialog .el-form-item {
  margin-bottom: 15px;
}
</style>